import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Modal,
  Row,
  Select,
  Slider,
  Space,
  Table,
  Typography,
} from "antd";
import {
  CaretRightFilled,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  HistoryOutlined,
  MoreOutlined,
  PauseOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import React, { useCallback, useState } from "react";
import { useRouteLoaderData, useSubmit } from "react-router-dom";

import { TVehicle } from "../types/TVehicle";
import { TDriver } from "../types/TDriver";
import { getRole } from "../services/localStorage";
import { TOrder } from "../types/TOrder";
import { MapHistory } from "../components/mapHistory";

const { confirm } = Modal;
const { Text } = Typography;

const VehiclePage: React.FC = () => {
  let { dataSource, drivers } = useRouteLoaderData("vehicle") as {
    dataSource: TVehicle[] | [];
    drivers: TDriver[] | [];
  };

  const submit = useSubmit();
  const [form] = Form.useForm();

  const [modal2Open, setModal2Open] = useState<boolean>(false);
  const [modalHistory, setModalHistory] = useState<boolean>(false);
  const [indexMarker, setIndexMarker] = useState<number>(0);
  const [indexNewMarker, setNewIndexMarker] = useState<number>(0);
  const [startRute, setStartRute] = useState<boolean>(false);
  const [pauseRute, setPauseRute] = useState<boolean>(true);
  const [event, setEvent] = useState<string>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [orders, setOrders] = useState<any[]>([]);
  const [rutes, setRutes] = useState<any[]>([]);
  const [vehicle, setVehicle] = useState<any>({});

  const handleStart = () => {
    if (rutes.length > 0) {
      setStartRute(false);
      setPauseRute(false);
      setEvent("start");
    } else {
      alert(
        `History perjalanan kendaraan ${vehicle.license_plate} tidak tersedia.`
      );
    }
  };

  const handlePause = useCallback(() => {
    setStartRute(true);
    setPauseRute(true);
    setEvent("paused");
  }, [setPauseRute]);

  const handleCloseModalHistory = useCallback(() => {
    setStartDate(undefined);
    setEndDate(undefined);
    setPauseRute(false);
    setVehicle([]);
    setOrders([]);
    setRutes([]);
    setIndexMarker(0);
    setModalHistory(false);
  }, [setModalHistory, setVehicle, setOrders, setRutes, setPauseRute]);

  const handleSetIndex = (idx: number) => {
    setStartRute(false);
    setPauseRute(false);
    setIndexMarker(idx);
    if (Math.floor((idx / 100) * rutes.length) === rutes.length - 2) {
      setStartRute(true);
      setPauseRute(true);
    }
  };

  const handleSetNewIndex = useCallback(
    (idx: number) => {
      setNewIndexMarker(idx);
    },
    [setNewIndexMarker]
  );

  const handleSave = () => {
    form.submit();
  };

  const handleNew = () => {
    form.resetFields();
    setModal2Open(true);
  };

  const handleEdit = (data: TVehicle) => {
    form.setFieldsValue(data);
    setModal2Open(true);
  };

  const onChangeStartDate = (data: string) => {
    setStartRute(false);
    if (data && orders.length > 0) {
      setStartDate(data);
      if (data && endDate) {
        const routes: any[] = [];
        orders
          ?.filter(
            (fl) =>
              Date.parse(fl.pickup) >= Date.parse(data) &&
              Date.parse(fl.pickup) <= Date.parse(endDate)
          )
          .map(
            (order: TOrder) =>
              order.route && routes.push(...JSON.parse(order.route || ""))
          );
        setRutes(routes);
        setStartRute(true);
      } else if (data) {
        const routes: any[] = [];
        orders
          ?.filter((fl) => fl.pickup === data)
          .map(
            (order: TOrder) =>
              order.route && routes.push(...JSON.parse(order.route || ""))
          );
        setRutes(routes);
        endDate !== "" && setStartRute(true);
      }
    }
  };

  const onChangeEndDate = (data: string) => {
    setStartRute(false);
    if (data && orders.length > 0) {
      setEndDate(data);
      if (data && startDate) {
        const routes: any[] = [];
        orders
          ?.filter(
            (fl) =>
              Date.parse(fl.pickup) >= Date.parse(startDate) &&
              Date.parse(fl.pickup) <= Date.parse(data)
          )
          .map(
            (order: TOrder) =>
              order.route && routes.push(...JSON.parse(order.route || ""))
          );
        setRutes(routes);
        setStartRute(true);
      } else if (data) {
        const routes: any[] = [];
        orders
          ?.filter((fl) => Date.parse(fl.pickup) <= Date.parse(data))
          .map(
            (order: TOrder) =>
              order.route && routes.push(...JSON.parse(order.route || ""))
          );
        setRutes(routes);
        startDate !== "" && setStartRute(true);
      }
    }
  };

  const handleHistory = (data: TVehicle) => {
    const routes: any[] = [];
    setVehicle(() => (data ? data : {}));
    setOrders(data?.orders ? data.orders : []);
    data?.orders?.map(
      (order: TOrder) =>
        order.route && routes.push(...JSON.parse(order.route || ""))
    );
    setRutes(routes);
    setPauseRute(true);
    setModalHistory(true);
  };

  const columns = [
    {
      title: "Plat Nomor",
      dataIndex: "license_plate",
      key: "license_plate",
      width: "10%",
      render: (_: any, record: any) => (
        <div style={{ textAlign: "center" }}>{record.license_plate}</div>
      ),
    },
    {
      title: "Tipe",
      dataIndex: "type",
      key: "type",
      width: "20%",
    },
    {
      title: "Nomor Casis",
      dataIndex: "chasis_serial",
      key: "chasis_serial",
    },
    {
      title: "Nomor Mesin",
      dataIndex: "engine_serial",
      key: "engine_serial",
    },
    {
      title: "Driver",
      dataIndex: "driver_name",
      key: "driver_id",
      width: "25%",
      render: (_: any, record: any) => <div>{record?.driver?.name}</div>,
    },
    {
      title: "Status",
      dataIndex: "is_ready",
      key: "is_ready",
      width: "10%",
      render: (_: any, record: any) => (
        <Text
          style={{
            color: record.is_ready ? "green" : "red",
          }}
        >
          {record.is_ready ? "Ready" : "On Track"}
        </Text>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "3%",
      render: (_: any, record: any) => {
        let items: MenuProps["items"] = [
          // {
          //   key: "1",
          //   label: "Edit",
          //   icon: <EditOutlined />,
          // },
          {
            key: "3",
            label: "History",
            icon: <HistoryOutlined />,
            disabled: record.orders.length === 0 ? true : false,
          },
          // {
          //   key: "2",
          //   danger: true,
          //   label: "Delete",
          //   icon: <DeleteOutlined />,
          // },
        ];
        if (getRole() === "ROLE_ADMIN") {
          items = [
            {
              key: "1",
              label: "Edit",
              icon: <EditOutlined />,
            },
            {
              key: "3",
              label: "History",
              icon: <HistoryOutlined />,
              disabled: record.orders.length === 0 ? true : false,
            },
            {
              key: "2",
              danger: true,
              label: "Delete",
              icon: <DeleteOutlined />,
            },
          ];
        }
        return (
          <Dropdown
            placement="bottomRight"
            menu={{
              items,
              onClick: ({ key }) => {
                if (key === "1") handleEdit(record);
                else if (key === "2") showDeleteConfirm(record.id);
                else if (key === "3") handleHistory(record);
              },
            }}
          >
            <p style={{ fontWeight: "bold", textAlign: "center" }}>
              <MoreOutlined
                style={{ fontWeight: "bold", textAlign: "center" }}
              />
            </p>
          </Dropdown>
        );
      },
    },
  ];

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: "Delete Vehicle",
      icon: <ExclamationCircleFilled />,
      centered: true,
      content: "Are you sure you want delete this data vehicle?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        submit({ action: "delete", id }, { method: "post" });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const validateForm = async () => {
    const vehicle = await dataSource.filter(
      (vhc) => vhc.license_plate === form.getFieldsValue().license_plate
    );
    if (vehicle.length > 0) {
      form.setFields([
        {
          name: "license_plate",
          errors: ["Plat Number is already registered"],
        },
      ]);
    } else {
      submit({ ...form.getFieldsValue() }, { method: "POST" });
      setModal2Open(false);
    }
  };

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Vehicles"
              extra={
                getRole() === "ROLE_ADMIN" ? (
                  <Button
                    type="primary"
                    ghost
                    onClick={handleNew}
                    icon={<PlusCircleOutlined />}
                  >
                    Add New Vehicle
                  </Button>
                ) : undefined
              }
            >
              <div className="table-responsive">
                <Table
                  dataSource={dataSource.map((dt) => ({
                    ...dt,
                    key: dt.id,
                  }))}
                  columns={
                    // getRole() === "ROLE_ADMIN"
                    //   ?
                    columns
                    // : columns.splice(0, columns.length - 1)
                  }
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        title="Vehicle"
        centered
        open={modal2Open}
        okText="Save"
        cancelText="Cancel"
        onOk={handleSave}
        onCancel={() => setModal2Open(false)}
      >
        <Form
          form={form}
          size="small"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          onFinish={() => {
            validateForm();
          }}
          autoComplete="off"
        >
          <Form.Item<TVehicle> name="id" hidden={true}>
            <Input />
          </Form.Item>

          <Form.Item<TVehicle>
            label="Plat Nomor"
            name="license_plate"
            rules={[
              {
                required: true,
                message: "Please input your license plate!",
              },
            ]}
          >
            <Input maxLength={10} />
          </Form.Item>

          <Form.Item<TVehicle>
            label="Nomor Casis"
            name="chasis_serial"
            rules={[
              {
                required: true,
                len: 17,
                message: "Please input your chasis_serial!",
              },
            ]}
          >
            <Input maxLength={17} />
          </Form.Item>

          <Form.Item<TVehicle>
            label="Nomor Mesin"
            name="engine_serial"
            rules={[
              {
                required: true,
                len: 17,
                message: "Please input your engine_serial!",
              },
            ]}
          >
            <Input maxLength={17} />
          </Form.Item>

          <Form.Item<TVehicle>
            label="Tipe"
            name="type"
            rules={[{ required: true, message: "Please input your type!" }]}
          >
            <Select placeholder="Select type vehicle" allowClear>
              <Select.Option value="Dutro 10 Ton">Dutro 10 Ton</Select.Option>
              <Select.Option value="Engkel 20 Ton">Engkel 20 Ton</Select.Option>
              <Select.Option value="Curah 30 Ton">Curah 30 Ton</Select.Option>
            </Select>
          </Form.Item>

          <Divider />
          <Form.Item<TVehicle>
            label="Driver"
            name="driver_id"
            // rules={[{ required: true, message: "Please input your driver!" }]}
          >
            <Select placeholder="Select driver" allowClear>
              {drivers?.map((driver: any) => (
                <Select.Option key={driver?.id} value={driver?.id}>
                  {driver?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={`History - ${vehicle?.license_plate}`}
        centered
        open={modalHistory}
        cancelButtonProps={{ style: { display: "none" } }}
        okText="Close"
        onOk={handleCloseModalHistory}
        onCancel={handleCloseModalHistory}
        destroyOnClose={true}
        width={1000}
      >
        <div className="table-responsive" style={{}}>
          <Space
            direction="horizontal"
            style={{ marginBottom: 20, marginTop: 20 }}
          >
            <label>Tanggal</label>
            <DatePicker
              placeholder="Start date"
              format="YYYY-MM-DD"
              onChange={(value: any, dateString: any) => {
                onChangeStartDate(dateString);
              }}
            />
            <DatePicker
              placeholder="End date"
              format="YYYY-MM-DD"
              onChange={(value: any, dateString: any) => {
                onChangeEndDate(dateString);
              }}
            />
          </Space>
          <MapHistory
            markers={rutes}
            handleIndex={handleSetIndex}
            events={event}
            indexMarker={indexNewMarker}
          />

          <Slider
            // value={rutes.length === 0 ? 0 : (indexMarker / rutes.length) * 100}
            value={indexMarker}
            onChange={handleSetNewIndex}
            className="mx-100"
            style={{ marginInline: 10 }}
          />
        </div>
        <div
          className="text-center"
          style={{ display: "flex", justifyContent: "center", gap: 4 }}
        >
          <button
            type="button"
            disabled={!startRute}
            style={{
              borderRadius: "100%",
              borderWidth: 0,
              background: !startRute ? "#d3d3d3" : "blue",
              color: "white",
            }}
            onClick={handleStart}
          >
            <CaretRightFilled />
          </button>
          <button
            type="button"
            disabled={pauseRute}
            style={{
              borderRadius: "100%",
              borderWidth: 0,
              background: pauseRute ? "#d3d3d3" : "gray",
              color: "white",
            }}
            onClick={handlePause}
          >
            <PauseOutlined />
          </button>
        </div>
      </Modal>
    </>
  );
};

export default VehiclePage;
