import React, { useEffect, useState } from "react";
import * as pkg from "@googlemaps/js-api-loader";

const { Loader } = pkg;

export function MapHistory({
  markers,
  handleIndex,
  events,
  indexMarker,
}: {
  markers: any[];
  handleIndex?: any;
  events?: any;
  indexMarker: number;
}) {
  const mapRef: any = React.useRef(null);
  const [map, setMap] = useState<any>(null);
  const [polyline, setPolyline] = useState<any>(null);
  const [marker, setMarker] = useState<any>(null);
  const [remaining, setRemaining] = useState<any>(0);
  const [timerAction, setTimerAction] = useState<{
    cancel: () => void;
    pause: () => void;
    resume: () => void;
  }>();

  let timer: {
    cancel: () => void;
    pause: () => void;
    resume: () => void;
  };

  useEffect(() => {
    timerAction && timerAction.cancel();
    timer && timer.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const countIndexMarker = Math.floor((indexMarker / 100) * markers.length);
    if (countIndexMarker > 0) {
      timerAction?.cancel();
      polyline?.getPath().clear();

      if (countIndexMarker <= markers.length - 2) {
        for (let index = 0; index < countIndexMarker; index++) {
          var coordsDeparture = markers[index];
          var coordsArrival = markers[index + 1];
          var departure = new window.google.maps.LatLng(
            coordsDeparture.lat,
            coordsDeparture.lng
          ); //Set to whatever lat/lng you need for your departure location
          var arrival = new window.google.maps.LatLng(
            coordsArrival.lat,
            coordsArrival.lng
          ); //Set to whatever lat/lng you need for your arrival location
          const are_we_there_yet =
            window.google.maps.geometry.spherical.interpolate(
              departure,
              arrival,
              0
            );
          polyline?.getPath().push(are_we_there_yet);
          marker?.setPosition(are_we_there_yet);
          map?.panTo(are_we_there_yet);
        }
        recursiveAnimate(countIndexMarker);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexMarker]);

  useEffect(() => {
    if (!map && !polyline) return;

    if (events === "start") {
      if (!timerAction) {
        handleIndex(0);
        polyline?.getPath().clear();
        recursiveAnimate(0);
      } else {
        timerAction.resume();
      }
      marker.setMap(map);
    }
    if (events === "paused") {
      timerAction && timerAction.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  function recursiveAnimate(index: number) {
    timerAction && timerAction.cancel();
    handleIndex((index / markers.length) * 100);
    var coordsDeparture = markers[index];
    var coordsArrival = markers[index + 1];

    var departure = new window.google.maps.LatLng(
      coordsDeparture.lat,
      coordsDeparture.lng
    ); //Set to whatever lat/lng you need for your departure location
    var arrival = new window.google.maps.LatLng(
      coordsArrival.lat,
      coordsArrival.lng
    ); //Set to whatever lat/lng you need for your arrival location
    var step = 0;
    var numSteps = 20; //Change this to set animation resolution
    var timePerStep = 300; //Change this to alter animation speed
    timer = InvervalTimer(function (arg: any) {
      step += 1;
      if (step > numSteps) {
        //clearInterval(interval);
        step = 0;
        timerAction?.cancel();
        timer?.cancel();
        if (index < markers.length - 2) {
          recursiveAnimate(index + 1);
        }
      } else {
        const are_we_there_yet =
          window.google.maps.geometry.spherical.interpolate(
            departure,
            arrival,
            step / numSteps
          );
        polyline?.getPath().push(are_we_there_yet);
        // moveMarker(are_we_there_yet);
        marker?.setPosition(are_we_there_yet);
        map?.panTo(are_we_there_yet);
      }
    }, timePerStep);
    setTimerAction(timer);
  }

  function InvervalTimer(callback: any, interval: any, arg?: any) {
    let timerId: any, startTime: any;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // remaining = 0;
    let state = 0; //  0 = idle, 1 = running, 2 = paused, 3= resumed
    let timeoutId: any;
    const pause = function () {
      if (state !== 1) return;

      setRemaining(interval - (new Date().getTime() - startTime));
      window.clearInterval(timerId);
      state = 2;
    };

    const resume = function () {
      if (state !== 2) return;

      state = 3;
      timeoutId = window.setTimeout(timeoutCallback, remaining, arg);
    };

    const timeoutCallback = function (timer: any) {
      if (state !== 3) return;
      clearTimeout(timeoutId);
      startTime = new Date();
      timerId = window.setInterval(function () {
        callback(arg);
      }, interval);
      state = 1;
    };

    const cancel = () => {
      clearInterval(timerId);
    };

    startTime = new Date();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    timerId = window.setInterval(function () {
      callback(arg);
    }, interval);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state = 1;
    return {
      cancel: cancel,
      pause: pause,
      resume: resume,
      timeoutCallback: timeoutCallback,
    };
  }

  const initGoogleMap = async () => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY as string,
      version: "weekly",
      libraries: ["geometry"],
    });
    const { Map } = await loader.importLibrary("maps");
    const position = {
      lat: markers
        ? markers.length === 0
          ? -6.2
          : parseFloat(markers[0].lat || "0")
        : -6.2,
      lng: markers
        ? markers.length === 0
          ? 106.816666
          : parseFloat(markers[0].lng || "0")
        : 106.816666,
    };

    const mapOptions: google.maps.MapOptions = {
      center: position,
      zoom: 14,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    return new Map(mapRef.current as unknown as HTMLDivElement, mapOptions);
  };

  const initPolylineMap = async (_map: any) => {
    return new window.google.maps.Polyline({
      path: [],
      strokeColor: "#FF0000",
      strokeWeight: 2,
      map: _map,
    });
  };

  const initMarkerMap = async (_map: any) => {
    return new google.maps.Marker({
      map: _map,
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: "#00F",
        fillOpacity: 1,
        strokeColor: "#00A",
        strokeOpacity: 1,
        strokeWeight: 1,
        scale: 7,
      },
    });
  };

  useEffect(() => {
    async function init() {
      const googleMap = await initGoogleMap();
      const polylineMap = await initPolylineMap(googleMap);
      const markerMap = await initMarkerMap(googleMap);
      setMap(googleMap);
      setPolyline(polylineMap);
      setMarker(markerMap);
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <script src="https://maps.googleapis.com/maps/api/js"></script>

      <div
        style={{ height: "600px", outline: "none", borderRadius: 8 }}
        ref={mapRef}
      />
    </>
  );
}
