import React, { useEffect, useState } from "react";
import * as pkg from "@googlemaps/js-api-loader";
import { TVehicle } from "../types/TVehicle";
import { TOrder } from "../types/TOrder";

const { Loader } = pkg;

export function MapDashboard({
  markers,
  orders,
}: {
  markers?: TVehicle[];
  orders?: TOrder[];
}) {
  const mapRef: any = React.useRef(null);
  const [map, setMap] = useState<any>(null);

  const moveMarkerOnRoute = async (
    map: any,
    pathCoords: any,
    license_plate: string
  ) => {
    const marker = new window.google.maps.Marker({
      position: {
        lat: pathCoords[0].lat,
        lng: pathCoords[0].lng,
      },
      map,
      icon: {
        path: window.google.maps.SymbolPath.CIRCLE,
        fillColor: "#00a300",
        fillOpacity: 1,
        strokeColor: "#00a300",
        strokeOpacity: 1,
        strokeWeight: 1,
        scale: 7,
        scaledSize: new window.google.maps.Size(40, 40),
      },
      optimized: false,
      zIndex: 99,
    });

    const infowindow = new google.maps.InfoWindow({
      content: license_plate,
    });

    marker.addListener("mouseover", () => {
      infowindow.open({
        anchor: marker,
        map,
      });
    });
    marker.addListener("mouseout", () => {
      infowindow.close();
    });

    for (let i = 0; i < pathCoords.length; i++) {
      await animatedMove(marker, marker.getPosition(), pathCoords[i], 1);
    }
  };

  const animatedMove = async (
    marker: any,
    moveFrom: any,
    moveTo: any,
    t: any,
    delta = 10000
  ) => {
    return new Promise((resolve) => {
      const deltalat = (moveTo?.lat - moveFrom?.lat()) / delta;
      const deltalng = (moveTo?.lng - moveFrom?.lng()) / delta;
      let delay = 10 * t,
        count = 0;
      for (let i = 0; i < delta; i++) {
        // eslint-disable-next-line no-loop-func
        (function (ind: any) {
          setTimeout(function () {
            let lat = marker.position.lat();
            let lng = marker.position.lng();
            lat += deltalat;
            lng += deltalng;
            marker.setPosition(new window.google.maps.LatLng(lat, lng));

            count++;
            if (count === delta) {
              resolve(marker);
            }
          }, delay * ind);
        })(i);
      }
    });
  };

  const initGoogleMap = async () => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY as string,
      version: "weekly",
      libraries: ["geometry"],
    });
    const { Map } = await loader.importLibrary("maps");
    const position = {
      lat: markers
        ? markers.length === 0
          ? -6.2
          : parseFloat(markers[0].lat || "0")
        : -6.2,
      lng: markers
        ? markers.length === 0
          ? 106.816666
          : parseFloat(markers[0].lng || "0")
        : 106.816666,
    };

    const mapOptions: google.maps.MapOptions = {
      center: position,
      zoom: markers ? (markers.length === 1 ? 11 : 9) : 11,
    };

    return new Map(mapRef.current as unknown as HTMLDivElement, mapOptions);
  };

  useEffect(() => {
    async function init() {
      const googleMap = await initGoogleMap();
      setMap(googleMap);
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!map) return;

    markers?.forEach((marker) => {
      const markerPin = new google.maps.Marker({
        map,
        position: {
          lat: marker ? parseFloat(marker.lat || "0") : 0,
          lng: marker ? parseFloat(marker.lng || "0") : 0,
        },
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          fillColor: "#00F",
          fillOpacity: 1,
          strokeColor: "#00A",
          strokeOpacity: 1,
          strokeWeight: 1,
          scale: 7,
        },
      });

      const infowindow = new google.maps.InfoWindow({
        content: marker.license_plate,
      });

      markerPin.addListener("mouseover", () => {
        infowindow.open({
          anchor: markerPin,
          map,
        });
      });
      markerPin.addListener("mouseout", () => {
        infowindow.close();
      });
    });

    // const routeOptions: any = new window.google.maps.Polyline({
    //   strokeOpacity: 0,
    //   icons: [
    //     {
    //       icon: {
    //         path: "M 0,-0.1 0,0.1",
    //         strokeOpacity: 0.8,
    //         strokeColor: "red",
    //         scale: 5,
    //       },
    //       offset: "0",
    //       repeat: "10px",
    //     },
    //   ],
    // });

    // const directionsService = new window.google.maps.DirectionsService();
    // const directionsRenderer = new window.google.maps.DirectionsRenderer({
    //   polylineOptions: routeOptions,
    // });
    // const request: any = {
    //   origin: {
    //     query:
    //       order?.sender_lat_back && order?.sender_lng_back
    //         ? `${order?.sender_lat_back},${order?.sender_lng_back}`
    //         : `${order?.sender_lat},${order?.sender_lng}`,
    //   },
    //   destination: {
    //     query:
    //       order?.receiver_lat_back && order?.receiver_lng_back
    //         ? `${order?.receiver_lat_back},${order?.receiver_lng_back}`
    //         : `${order?.receiver_lat},${order?.receiver_lng}`,
    //   },
    //   travelMode: google.maps.TravelMode.DRIVING,
    // };
    // directionsService.route(request, function (response: any, status) {
    //   if (status === "OK") {
    //     directionsRenderer.setDirections(response);
    //     directionsRenderer.setMap(map);
    // moveMarkerOnRoute(map, response.routes[0].overview_path);
    //   }
    // });
    if (orders!.filter((order) => order.status === true).length > 0) {
      orders!
        .filter((order) => order.status === true)
        .map(
          // eslint-disable-next-line array-callback-return
          (order: any) => {
            order?.route &&
              moveMarkerOnRoute(
                map,
                order.route ? JSON.parse(order.route) : null,
                order?.vehicle?.license_plate
              );
          }
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return (
    <>
      <script src="https://maps.googleapis.com/maps/api/js"></script>

      <div
        style={{ height: "600px", outline: "none", borderRadius: 8 }}
        ref={mapRef}
      />
    </>
  );
}
