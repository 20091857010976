import React, { useEffect, useState } from "react";
import * as pkg from "@googlemaps/js-api-loader";
import { TVehicle } from "../types/TVehicle";
import { TOrder } from "../types/TOrder";
import { useSubmit } from "react-router-dom";
// import { Loader } from "@googlemaps/js-api-loader";
// import { useSubmit } from "react-router-dom";

const { Loader } = pkg;

export function Map({
  markers,
  order,
}: {
  markers?: TVehicle[];
  order?: TOrder | undefined;
}) {
  const submit = useSubmit();
  const mapRef: any = React.useRef(null);
  const [map, setMap] = useState<any>(null);

  // const calculateAndDisplayRoute = async function (
  //   directionsService: any,
  //   directionsRenderer: any
  // ) {
  //   try {
  //     const res = await directionsService.route({
  //       origin: {
  //         query:
  //           order?.sender_lat_back && order?.sender_lng_back
  //             ? `${order?.sender_lat_back},${order?.sender_lng_back}`
  //             : `${order?.sender_lat},${order?.sender_lng}`,
  //         // "-4.8828902321171395, 105.21360587237072",
  //       },
  //       destination: {
  //         query:
  //           order?.receiver_lat_back && order?.receiver_lng_back
  //             ? `${order?.receiver_lat_back},${order?.receiver_lng_back}`
  //             : `${order?.receiver_lat},${order?.receiver_lng}`,
  //         // "-4.1067328759970625, 104.16113604117484",
  //       },
  //       waypoints: order?.transits?.map((trans) => ({
  //         location: `${trans.lat},${trans.lng}`,
  //         stopover: true,
  //       })),
  //       // [
  //       //   {
  //       //     location: "-4.8349521,104.897095",
  //       //     // "-4.856001226307208, 104.92754996953286",
  //       //     stopover: true,
  //       //   },
  //       // ],
  //       travelMode: google.maps.TravelMode.DRIVING,
  //       avoidTolls: false,
  //       avoidHighways: true,
  //       provideRouteAlternatives: true,
  //       // drivingOptions: {
  //       //   departureTime: new Date(),
  //       //   trafficModel: "pessimistic",
  //       //   // modes: ["BUS"],
  //       // },
  //     });

  // if (order && !order.route) {
  //   order.id &&
  //     submit(
  //       {
  //         id: order?.id,
  //         route: JSON.stringify(
  //           res?.routes[0].overview_path.map((path: any) => ({
  //             lat: path.lat(),
  //             lng: path.lng(),
  //           }))
  //         ),
  //       },
  //       { method: "POST", action: "/orders" }
  //     );
  // }

  //     if (
  //       order &&
  //       !order.route_back &&
  //       order.sender_lat_back &&
  //       order.sender_lng_back &&
  //       order.receiver_lat_back &&
  //       order.receiver_lng_back
  //     ) {
  //       order.id &&
  //         submit(
  //           {
  //             id: order?.id,
  //             route_back: JSON.stringify(
  //               res?.routes[0].overview_path.map((path: any) => ({
  //                 lat: path.lat(),
  //                 lng: path.lng(),
  //               }))
  //             ),
  //           },
  //           { method: "POST", action: "/orders" }
  //         );
  //     }

  //     directionsRenderer.setDirections(res);
  //     moveMarkerOnRoute(_map, res.routes[0].overview_path);
  //   } catch (e: any) {
  //     console.log(`Directions request failed
  //         Code: ${e.code}
  //         Message: ${e.message} `);
  //   }
  // };

  const moveMarkerOnRoute = async (map: any, pathCoords: any) => {
    const marker = new window.google.maps.Marker({
      position: pathCoords[0],
      map,
      icon: {
        path: window.google.maps.SymbolPath.CIRCLE,
        fillColor: "#00F",
        fillOpacity: 1,
        strokeColor: "#00A",
        strokeOpacity: 1,
        strokeWeight: 1,
        scale: 7,
        // scaledSize: new window.google.maps.Size(40, 40),
      },
      optimized: false,
      zIndex: 99,
    });

    for (let i = 0; i < pathCoords.length; i++) {
      await animatedMove(marker, marker.getPosition(), pathCoords[i], 10);
    }
  };

  const animatedMove = async (
    marker: any,
    moveFrom: any,
    moveTo: any,
    t: any,
    delta = 100
  ) => {
    return new Promise((resolve) => {
      const deltalat = (moveTo?.lat() - moveFrom?.lat()) / delta;
      const deltalng = (moveTo?.lng() - moveFrom?.lng()) / delta;
      let delay = 10 * t,
        count = 0;
      for (let i = 0; i < delta; i++) {
        // eslint-disable-next-line no-loop-func
        (function (ind: any) {
          setTimeout(function () {
            let lat = marker.position.lat();
            let lng = marker.position.lng();
            lat += deltalat;
            lng += deltalng;
            marker.setPosition(new window.google.maps.LatLng(lat, lng));

            count++;
            if (count === delta) {
              resolve(marker);
            }
          }, delay * ind);
        })(i);
      }
    });
  };

  const initGoogleMap = async () => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY as string,
      version: "weekly",
      libraries: ["geometry"],
    });
    const { Map } = await loader.importLibrary("maps");
    const position = {
      lat: markers
        ? markers.length === 0
          ? -6.2
          : parseFloat(markers[0].lat || "0")
        : -6.2,
      lng: markers
        ? markers.length === 0
          ? 106.816666
          : parseFloat(markers[0].lng || "0")
        : 106.816666,
    };

    const mapOptions: google.maps.MapOptions = {
      center: position,
      zoom: markers ? (markers.length === 1 ? 10 : 8) : 10,
      // mapId: "MY_NEXTJS_MAPID",
    };

    // return new Map(mapRef.current, {
    //   center: position,
    //   zoom: 12,
    // });
    return new Map(mapRef.current as unknown as HTMLDivElement, mapOptions);
  };

  useEffect(() => {
    async function init() {
      const googleMap = await initGoogleMap();
      setMap(googleMap);
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!map) return;

    const routeOptions: any = new window.google.maps.Polyline({
      // strokeOpacity: 0,
      strokeOpacity: 0.5,
      strokeColor: "#FF0000",
      // icons: [
      //   {
      //     icon: {
      //       path: "M 0,-0.1 0,0.1",
      //       strokeOpacity: 0.8,
      //       strokeColor: "red",
      //       scale: 5,
      //     },
      //     offset: "0",
      // repeat: "10px",
      //   },
      // ],
    });

    const directionsService = new window.google.maps.DirectionsService();
    const directionsRenderer = new window.google.maps.DirectionsRenderer({
      polylineOptions: routeOptions,
    });
    const request: any = {
      origin: {
        query:
          order?.sender_lat_back && order?.sender_lng_back
            ? `${order?.sender_lat_back},${order?.sender_lng_back}`
            : `${order?.sender_lat},${order?.sender_lng}`,
      },
      destination: {
        query:
          order?.receiver_lat_back && order?.receiver_lng_back
            ? `${order?.receiver_lat_back},${order?.receiver_lng_back}`
            : `${order?.receiver_lat},${order?.receiver_lng}`,
      },
      travelMode: google.maps.TravelMode.DRIVING,
    };
    directionsService.route(request, function (response: any, status) {
      if (status === "OK") {
        console.log(response);
        if (order && !order.route) {
          order.id &&
            submit(
              {
                id: order?.id,
                route: JSON.stringify(
                  response?.routes[0].overview_path.map((path: any) => ({
                    lat: path.lat(),
                    lng: path.lng(),
                  }))
                ),
              },
              { method: "POST", action: "/orders" }
            );
        }

        directionsRenderer.setDirections(response);
        directionsRenderer.setMap(map);
        moveMarkerOnRoute(map, response.routes[0].overview_path);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  // useEffect(() => {
  //   const initMap = async () => {
  //     const loader = new Loader({
  //       apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY as string,
  //       version: "weekly",
  //     });
  //     const { Map } = await loader.importLibrary("maps");

  //     const position = {
  //       lat: markers
  //         ? markers.length === 0
  //           ? -6.2
  //           : parseFloat(markers[0].lat || "0")
  //         : -6.2,
  //       lng: markers
  //         ? markers.length === 0
  //           ? 106.816666
  //           : parseFloat(markers[0].lng || "0")
  //         : 106.816666,
  //     };

  //     const mapOptions: google.maps.MapOptions = {
  //       center: position,
  //       zoom: markers ? (markers.length === 1 ? 10 : 8) : 10,
  //       mapId: "MY_NEXTJS_MAPID",
  //     };

  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //     const map = new Map(
  //       mapRef.current as unknown as HTMLDivElement,
  //       mapOptions
  //     );
  //     setMap(map);

  //     const google = window.google; // Fix for ESLint Err google is not defined
  //     if (order) {
  //       const directionsService = new google.maps.DirectionsService();
  //       const directionsRenderer = new google.maps.DirectionsRenderer();
  //       directionsRenderer.setOptions({
  //         polylineOptions: {
  //           strokeOpacity: 0.5,
  //           strokeColor: "#FF0000",
  //         },
  //       });
  //       directionsRenderer.setMap(map);
  //       calculateAndDisplayRoute(directionsService, directionsRenderer);
  //     }

  //     // markers?.forEach((marker) => {
  //     //   const markerPin = new google.maps.Marker({
  //     //     map,
  //     //     position: {
  //     //       lat: marker ? parseFloat(marker.lat || "0") : 0,
  //     //       lng: marker ? parseFloat(marker.lng || "0") : 0,
  //     //     },
  //     //     icon: {
  //     //       path: google.maps.SymbolPath.CIRCLE,
  //     //       fillColor: "#00F",
  //     //       fillOpacity: 1,
  //     //       strokeColor: "#00A",
  //     //       strokeOpacity: 1,
  //     //       strokeWeight: 1,
  //     //       scale: 7,
  //     //     },
  //     //   });

  //     //   // const contentString =
  //     //   //   '<div id="content"><div id="bodyContent">' +
  //     //   //   marker.license_plate +
  //     //   //   "</div></div>";

  //     //   const infowindow = new google.maps.InfoWindow({
  //     //     content: marker.license_plate,
  //     //     // ariaLabel: "Uluru",
  //     //   });

  //     //   markerPin.addListener("mouseover", () => {
  //     //     infowindow.open({
  //     //       anchor: markerPin,
  //     //       map,
  //     //     });
  //     //   });
  //     //   markerPin.addListener("mouseout", () => {
  //     //     infowindow.close();
  //     //   });
  //     // });
  //     // startAnimation();
  //   };
  //   initMap();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [order]);

  // function startAnimation() {
  // eol = polyline.Distance();
  // map.setCenter(polyline.getPath().getAt(0));
  // map.addOverlay(new google.maps.Marker(polyline.getAt(0),G_START_ICON));
  // map.addOverlay(new GMarker(polyline.getVertex(polyline.getVertexCount()-1),G_END_ICON));
  // marker = new google.maps.Marker({location:polyline.getPath().getAt(0)} /* ,{icon:car} */);
  // map.addOverlay(marker);
  // poly2 = new google.maps.Polyline({
  //   path: [polyline.getPath().getAt(0)],
  //   strokeColor: "#0000FF",
  //   strokeWeight: 10,
  // });
  // map.addOverlay(poly2);
  // setTimeout("animate(50)", 2000); // Allow time for the initial map display
  // }

  return (
    <>
      <script src="https://maps.googleapis.com/maps/api/js"></script>

      <div
        style={{ height: "600px", outline: "none", borderRadius: 8 }}
        ref={mapRef}
      />
    </>
  );
}
