import { ActionFunctionArgs, json, redirect } from "react-router-dom";

import { setTokens } from "../services/localStorage";
import * as AuthAction from "./auth.action";

export async function loginAction({ request }: ActionFunctionArgs) {
  try {
    const data: any = Object.fromEntries(await request.formData());

    const response: any = await AuthAction.login(data);
    setTokens(response?.data);

    let redirectTo = data.redirectTo as string | null;
    return redirect(redirectTo || "/");
  } catch (error: any) {
    if (error.response.status === 401) {
      return json(
        { status: 401, message: error?.response?.data?.message },
        { status: 401 }
      );
    }
  }
}
