import {
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import {
  DashboardPage,
  ChangePasswordPage,
  OrderPage,
  AccountPage,
  DriverPage,
  VehiclePage,
  PageNotFoundPage,
  LoginPage,
} from "./pages";
import PrivateRoutes from "./pages/PrivateRoutes";
import { useAppDispatch } from "./reducers/hooks";
import { logout } from "./slices/loginSlice";
import { loginLoader } from "./loaders/loginLoader";
import { loginAction } from "./actions/login.action";
import { protectedLoader } from "./loaders/protectedLoader";
import { driverLoader } from "./loaders/driverLoader";
import { driverAction } from "./actions/driver.action";
import { vehicleLoader } from "./loaders/vehicleLoader";
import { vehicleAction } from "./actions/vehicle.action";
import { dashboardLoader } from "./loaders/dashboardLoader";
import { orderLoader } from "./loaders/orderLoader";
import { orderAction } from "./actions/order.action";
import { accountLoader } from "./loaders/accountLoader";
import { userAction } from "./actions/user.action";

function App() {
  const dispatch = useAppDispatch();

  const router = createBrowserRouter([
    {
      path: "/",
      loader: protectedLoader,
      element: <PrivateRoutes />,
      errorElement: <PageNotFoundPage />,
      children: [
        {
          id: "dashboard",
          index: true,
          loader: dashboardLoader,
          element: <DashboardPage />,
        },
        {
          path: "/change-password",
          element: <ChangePasswordPage />,
        },
        {
          id: "order",
          path: "/orders",
          loader: orderLoader,
          action: orderAction,
          element: <OrderPage />,
        },
        {
          id: "user",
          path: "/users",
          loader: accountLoader,
          action: userAction,
          element: <AccountPage />,
        },
        {
          id: "driver",
          loader: driverLoader,
          action: driverAction,
          path: "/drivers",
          element: <DriverPage />,
        },
        {
          id: "vehicle",
          path: "/vehicles",
          loader: vehicleLoader,
          action: vehicleAction,
          element: <VehiclePage />,
        },
      ],
    },
    {
      path: "/login",
      loader: loginLoader,
      action: loginAction,
      element: <LoginPage />,
    },
    {
      path: "/logout",
      async action() {
        await dispatch(logout());
        return redirect("/login");
      },
    },
  ]);

  

  return <RouterProvider router={router} />;
}

export default App;
