import httpCommon from "./http-common";

export const login = async (data: any) => {
  try {
    return await httpCommon.post<any>("/auth/signin", data);
  } catch (error) {
    throw error;
  }
};

export const refreshToken = async (data: any) => {
  return await httpCommon.put<any>("/auth/refreshtoken" + data.id, data);
};
