import { Card, Col, List, Row, Typography } from "antd";
import {
  AimOutlined,
  CarOutlined,
  TeamOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import React from "react";
import { useRouteLoaderData } from "react-router-dom";

import { MapDashboard } from "../components/mapDashboard";

import { TVehicle } from "../types/TVehicle";
import { TDriver } from "../types/TDriver";
import { TOrder } from "../types/TOrder";

// import { useEffect, useState } from "react";
// import { Loader } from "@googlemaps/js-api-loader";

const { Title } = Typography;

const DashboardPage: React.FC = () => {
  // const [loadMap, setLoadMap] = useState(false);
  // useEffect(() => {
  // const options: any = {
  //   apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY as string,
  //   version: "weekly",
  //   libraries: ["geometry"],
  // };

  // new Loader(options)
  //   .load()
  //   .then(() => {
  //     setLoadMap(true);
  //   })
  //   .catch((e) => {
  //     console.error(
  //       "Sorry, something went wrong: Please try again later. Error:",
  //       e
  //     );
  //   });
  // }, []);

  let { orders, vehicles, drivers } = useRouteLoaderData("dashboard") as {
    orders: TOrder[] | [];
    vehicles: TVehicle[] | [];
    drivers: TDriver[] | [];
  };

  const count = [
    {
      today: "Vehicles",
      title: vehicles?.length || 0,
      icon: <CarOutlined />,
    },
    {
      today: "Drivers",
      title: drivers?.length || 0,
      icon: <TeamOutlined />,
    },
    {
      today: "Orders",
      title: orders?.length || 0,
      icon: <ShoppingOutlined />,
    },
  ];

  return (
    <>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {count.map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>{c.today}</span>
                      <Title level={3}>{c.title}</Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">{c.icon}</div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={18} xl={18}>
            <MapDashboard
              markers={
                vehicles.filter(
                  (fl) =>
                    !orders
                      .map((order) => order.vehicle?.license_plate)
                      .includes(fl.license_plate)
                ) as TVehicle[]
              }
              orders={orders as TOrder[]}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Card title="Vehicle">
              <List
                style={{ overflowX: "scroll", height: "60vh" }}
                min-width="100%"
                className="header-notifications-dropdown "
                itemLayout="horizontal"
                dataSource={vehicles || ([] as any)}
                renderItem={(item: any) => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.license_plate}
                      description={
                        <p style={{ fontSize: 12, color: "gray" }}>
                          <AimOutlined
                            style={{ color: item.is_ready ? "green" : "red" }}
                          />
                          {` ${item.is_ready ? "last position" : "last from"} ${
                            item.lat | 0
                          }, ${item.lng | 0}`}
                        </p>
                      }
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DashboardPage;
